$(document).on("turbolinks:load", function() {
  $('.pm-nav-tabs li').off("click").on("click", function() {
    // Remove 'active' class from all tabs and panes
    $('.pm-nav-tabs li').removeClass('active');
    $('.pm-tab-content .tab-pane').removeClass('active');

    // Add 'active' class to clicked tab and corresponding pane
    $(this).addClass('active');
    $('#' + $(this).data('tab')).addClass('active');
  });
});
