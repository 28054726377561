import * as echarts from 'echarts';

let initIpReputationIndexCharts = () => {
  const chartDoms = document.querySelectorAll('.ip-reputation-index-chart');
  if (!chartDoms.length) return;

  chartDoms.forEach((chartDom) => {
    const ipReputationData = chartDom.dataset.ipReputation;
    if (!ipReputationData) return;

    const ipReputation = JSON.parse(ipReputationData);
    const myChart = echarts.init(chartDom);

    const colors = {
      BAD: '#F44336',
      LOW: '#FA4A80',
      MEDIUM: '#FE9E30',
      HIGH: '#2DCC73'
    };

    const orderedKeys = ['BAD', 'LOW', 'MEDIUM', 'HIGH'];

    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'shadow' },
        formatter: (params) => {
          return params
            .map(({ seriesName, value, color }) =>
              `<span style="color:${color}"><b>${seriesName}: ${value}%</b></span>`
            )
            .join('<br>');
        }
      },
      xAxis: { type: 'value', max: 100, show: false },
      yAxis: { type: 'category', data: [''], show: false },
      grid: { left: 0, right: 0, top: 10, bottom: 10 },
      series: orderedKeys.map((key) => ({
        name: key,
        type: 'bar',
        stack: 'gap-free',
        data: [ipReputation[key] || 0],
        barWidth: 30,
        itemStyle: {
          color: colors[key],
          borderWidth: ipReputation[key] < 5 ? 0.1 : 0.5,
          borderColor: '#fff'
        },
        emphasis: { focus: 'series' }
      }))
    };

    myChart.setOption(option);
    window.addEventListener('resize', () => myChart.resize());
  });
};

document.addEventListener('turbolinks:load', () => {
  initIpReputationIndexCharts();
});
