import * as echarts from 'echarts';

// Spam Rate Chart
let myChartList = [];

let initSpamRateChart = () => {
  const chartDom = document.getElementsByClassName('spam-rate-chart');
  if (!chartDom.length) return;

  for (let i = 0; i < chartDom.length; i++) {
    if (chartDom[i].clientWidth === 0 || chartDom[i].clientHeight === 0) {
      setTimeout(initSpamRateChart, 100);
      return;
    }

    const spamRateData = JSON.parse(chartDom[i].dataset.spamRate);

    const dates = Object.keys(spamRateData);
    const values = Object.values(spamRateData);

    const myChart = echarts.init(chartDom[i]);

    const option = {
      title: { text: 'Spam Rate Over Time', left: 'center' },
      tooltip: { trigger: 'axis' },
      grid: { left: '10%', right: '10%', top: '15%', bottom: '15%' },
      xAxis: { 
        type: 'category', 
        data: dates,
        splitLine: { show: true }
      },
      yAxis: { 
        type: 'value', 
        axisLabel: { formatter: '{value}%' },
        splitLine: { show: true },
        axisLine: { lineStyle: { color: '#000' } }
      },
      series: [{
        data: values,
        type: 'line',
        smooth: true,
        itemStyle: { color: '#6c72c1' }
      }]
    };

    myChart.setOption(option);
    myChartList.push(myChart);
  }
};

let initIpReputationChart = () => {
  const chartDom = document.getElementsByClassName('ip-reputation-chart');
  if (!chartDom.length) return;

  for (let i = 0; i < chartDom.length; i++) {
    if (chartDom[i].clientWidth === 0 || chartDom[i].clientHeight === 0) {
      setTimeout(initIpReputationChart, 100);
      return;
    }

    const ipReputationData = JSON.parse(chartDom[i].dataset.ipReputation);
    const reputationInstructionDiv = document.getElementById('ip-reputation-instruction');
    const reputationMessageDiv = document.getElementById('ip-reputation-message');
    const reputationTable = document.getElementById('ip-reputation-table'); 
    reputationTable.style.display = "none";

    const dates = Object.keys(ipReputationData);
    const badValues = dates.map(date => ipReputationData[date].BAD.percentage || 0);
    const lowValues = dates.map(date => ipReputationData[date].LOW.percentage || 0);
    const mediumValues = dates.map(date => ipReputationData[date].MEDIUM.percentage || 0);
    const highValues = dates.map(date => ipReputationData[date].HIGH.percentage || 0);

    const myChart = echarts.init(chartDom[i]);

    const option = {
      title: { text: 'IP Reputation Over Time', left: 'center' },
      tooltip: { 
        trigger: 'item', // Show tooltip only for hovered segment
        formatter: function(params) {
          const date = params.name;
          const reputation = params.seriesName;
          const percentage = params.value.toFixed(2) + "%"; // Format to 2 decimal places
          return `${date}<br/>${params.marker} ${reputation}: <span style="color:${params.color}; font-weight:bold;">${percentage}</span>`;
        }
      },
      grid: { left: '10%', right: '10%', top: '15%', bottom: '15%' },
      xAxis: { 
        type: 'category', 
        data: dates,
        splitLine: { show: true }
      },
      yAxis: { 
        type: 'value', 
        min: 0,
        max: 'dataMax', 
        axisLabel: { formatter: '{value}%' },
        splitLine: { show: true },
        axisLine: { lineStyle: { color: '#000' } }
      },
      legend: { data: ['Bad', 'Low', 'Medium', 'High'], bottom: 0 },
      series: [
        { name: 'Bad', type: 'bar', stack: 'total', data: badValues, itemStyle: { color: '#F44336' } },
        { name: 'Low', type: 'bar', stack: 'total', data: lowValues, itemStyle: { color: '#FA4A80' } },
        { name: 'Medium', type: 'bar', stack: 'total', data: mediumValues, itemStyle: { color: '#FE9E30' } },
        { name: 'High', type: 'bar', stack: 'total', data: highValues, itemStyle: { color: '#2DCC73' } }
      ]
    };

    myChart.setOption(option);

    myChart.on('click', function(params) {
      const clickedDate = params.name;
      const clickedReputation = params.seriesName.toUpperCase();
      const formattedDate = new Date(clickedDate).toLocaleDateString('en-US', { month: 'short', day: '2-digit' });

      reputationInstructionDiv.style.display = "none";
      reputationMessageDiv.style.display = "block";
      reputationMessageDiv.innerText = `${clickedReputation} reputation IPs for ${formattedDate}`;

      const ipList = ipReputationData[clickedDate]?.[clickedReputation]?.ips || [];

      if (ipList.length > 0) {
        let tableHtml = ``;
        ipList.forEach(ip => {
          tableHtml += `<tr><td class="border border-gray-300 px-4 py-2 singal-column">${ip}</td></tr>`;
        });

        reputationTable.innerHTML = tableHtml;
        reputationTable.style.display = "table";
      } else {
        reputationTable.style.display = "none";
      }
    });

    myChartList.push(myChart);
  }
};

let initDomainReputationChart = () => {
  const chartDom = document.getElementsByClassName('domain-reputation-chart');
  if (!chartDom.length) return;

  for (let i = 0; i < chartDom.length; i++) {
    if (chartDom[i].clientWidth === 0 || chartDom[i].clientHeight === 0) {
      setTimeout(initDomainReputationChart, 100);
      return;
    }

    const reputationData = JSON.parse(chartDom[i].dataset.domainReputation);

    const dates = Object.keys(reputationData);
    const values = Object.values(reputationData).map(reputation => {
      const reputationMap = { "High": 4, "Medium": 3, "Low": 2, "Bad": 1 };
      return reputationMap[reputation] || null;
    });

    const myChart = echarts.init(chartDom[i]);

    const option = {
      title: { text: 'Domain Reputation Over Time', left: 'center' },
      tooltip: { trigger: 'axis', formatter: params => {
        const reputationMap = { 4: "High", 3: "Medium", 2: "Low", 1: "Bad" };
        return `${params[0].name}: <strong>${reputationMap[params[0].value]}</strong>`;
      }},
      grid: { left: '10%', right: '10%', top: '15%', bottom: '15%' },
      xAxis: { type: 'category', data: dates, splitLine: { show: true } },
      yAxis: { 
        type: 'value',
        min: 1, max: 4,
        axisLabel: { formatter: value => ({ 4: "High", 3: "Medium", 2: "Low", 1: "Bad" }[value] || '') },
        splitLine: { show: true },
        axisLine: { lineStyle: { color: '#000' } }
      },
      series: [{
        data: values,
        type: 'line',
        smooth: true,
        itemStyle: { color: '#6c72c1' }
      }]
    };

    myChart.setOption(option);
    myChartList.push(myChart);
  }
};

let initFeedbackLoopChart = () => {
  const chartDom = document.getElementsByClassName('feedback-loop-chart');
  if (!chartDom.length) return;

  for (let i = 0; i < chartDom.length; i++) {
    if (chartDom[i].clientWidth === 0 || chartDom[i].clientHeight === 0) {
      setTimeout(initFeedbackLoopChart, 100);
      return;
    }

    const feedbackLoopData = JSON.parse(chartDom[i].dataset.feedbackLoop);
    const feedbackMessageDiv = document.getElementById('feedback-loop-message');
    const feedbackTable = document.getElementById('feedback-loop-table');
    const feedbackTableBody = feedbackTable.querySelector('tbody');
    const feedbackInstructionDiv = document.getElementById('feedback-loop-instruction');

    const dates = Object.keys(feedbackLoopData);
    const spamRates = dates.map(date => feedbackLoopData[date].user_reported_spam_ratio);
    const identifiers = dates.map(date => feedbackLoopData[date].identifiers);

    const myChart = echarts.init(chartDom[i]);

    const option = {
      title: { text: 'Feedback Loop Over Time', left: 'center' },
      tooltip: { trigger: 'axis' },
      legend: { data: ['Spam Rate (%)', 'Identifiers'], bottom: 0 },
      grid: { left: '10%', right: '10%', top: '15%', bottom: '15%' },
      xAxis: { type: 'category', data: dates, splitLine: { show: true } },
      yAxis: [
        { 
          type: 'value', 
          name: 'Spam Rate (%)', 
          min: 0, max: 100, 
          axisLabel: { formatter: '{value}%' }, 
          splitLine: { show: true } 
        },
        { 
          type: 'value', 
          name: 'Identifiers', 
          min: 0, 
          axisLabel: { formatter: '{value}' }, 
          splitLine: { show: false } 
        }
      ],
      series: [
        { name: 'Spam Rate (%)', type: 'line', data: spamRates, yAxisIndex: 0, itemStyle: { color: 'blue' }, smooth: true },
        { name: 'Identifiers', type: 'line', data: identifiers, yAxisIndex: 1, itemStyle: { color: 'red' }, smooth: true }
      ]
    };

    myChart.setOption(option);

    // Click event for table display
    myChart.on('click', function(params) {
      const clickedDate = params.name;
      const details = feedbackLoopData[clickedDate] || { spam_rate: 0, identifiers: 0 };

      // Hide instruction message
      feedbackInstructionDiv.style.display = "none";

      // Show selected date message
      feedbackMessageDiv.innerText = `Feedback Loop Data for ${clickedDate}:`;

      // Populate table with clicked data
      feedbackTableBody.innerHTML = `
        <tr>
          <td class="border border-gray-300 px-4 py-2">${clickedDate}</td>
          <td class="border border-gray-300 px-4 py-2">${details.spam_rate.toFixed(2)}%</td>
          <td class="border border-gray-300 px-4 py-2">${details.identifiers}</td>
        </tr>
      `;

      // Display the table
      feedbackTable.style.display = "table";
    });

    myChartList.push(myChart);
  }
};

let initAuthenticationChart = () => {
  const chartDom = document.getElementsByClassName('authentication-chart');
  if (!chartDom.length) return;

  for (let i = 0; i < chartDom.length; i++) {
    if (chartDom[i].clientWidth === 0 || chartDom[i].clientHeight === 0) {
      setTimeout(initAuthenticationChart, 100);
      return;
    }

    const authenticationData = JSON.parse(chartDom[i].dataset.authentication);
    const dates = Object.keys(authenticationData);

    const spfData = dates.map(date => authenticationData[date].spf || 0);
    const dkimData = dates.map(date => authenticationData[date].dkim || 0);
    const dmarcData = dates.map(date => authenticationData[date].dmarc || 0);

    const myChart = echarts.init(chartDom[i]);

    const option = {
      title: { text: 'Authentication Over Time', left: 'center' },
      tooltip: { trigger: 'axis' },
      legend: { data: ['SPF', 'DKIM', 'DMARC'], bottom: 0 },
      grid: { left: '10%', right: '10%', top: '15%', bottom: '15%' },
      xAxis: { 
        type: 'category', 
        data: dates,
        splitLine: { show: true }
      },
      yAxis: { 
        type: 'value', 
        axisLabel: { formatter: '{value}%' },
        splitLine: { show: true },
        axisLine: { lineStyle: { color: '#000' } }
      },
      series: [
        {
          name: 'SPF',
          data: spfData,
          type: 'line',
          smooth: true,
          itemStyle: { color: '#FA4A80' }
        },
        {
          name: 'DKIM',
          data: dkimData,
          type: 'line',
          smooth: true,
          itemStyle: { color: '#50B8FD' }
        },
        {
          name: 'DMARC',
          data: dmarcData,
          type: 'line',
          smooth: true,
          itemStyle: { color: '#FE9E30' }
        }
      ]
    };

    myChart.setOption(option);
    myChartList.push(myChart);
  }
};

let initEncryptionChart = () => {
  const chartDom = document.getElementsByClassName('encryption-chart');
  if (!chartDom.length) return;

  for (let i = 0; i < chartDom.length; i++) {
    if (chartDom[i].clientWidth === 0 || chartDom[i].clientHeight === 0) {
      setTimeout(initEncryptionChart, 100);
      return;
    }

    const encryptionData = JSON.parse(chartDom[i].dataset.encryption);
    const dates = Object.keys(encryptionData);

    const inboundTLS = dates.map(date => encryptionData[date].inbound_tls || 0);
    const outboundTLS = dates.map(date => encryptionData[date].outbound_tls === 'N/A' ? null : encryptionData[date].outbound_tls);

    const myChart = echarts.init(chartDom[i]);

    const option = {
      title: { text: 'Encryption Over Time', left: 'center' },
      tooltip: { trigger: 'axis' },
      legend: { data: ['Inbound TLS', 'Outbound TLS'], bottom: 0 },
      grid: { left: '10%', right: '10%', top: '15%', bottom: '15%' },
      xAxis: { 
        type: 'category', 
        data: dates,
        splitLine: { show: true }
      },
      yAxis: { 
        type: 'value', 
        axisLabel: { formatter: '{value}%' },
        splitLine: { show: true },
        axisLine: { lineStyle: { color: '#000' } }
      },
      series: [
        {
          name: 'Inbound TLS',
          data: inboundTLS,
          type: 'line',
          smooth: true,
          itemStyle: { color: '#6c72c1' }
        },
        {
          name: 'Outbound TLS',
          data: outboundTLS,
          type: 'line',
          smooth: true,
          itemStyle: { color: '#F44336' }
        }
      ]
    };

    myChart.setOption(option);
    myChartList.push(myChart);
  }
};

let initDeliveryErrorsChart = () => {
  const chartDom = document.getElementsByClassName('delivery-errors-chart');
  if (!chartDom.length) return;

  for (let i = 0; i < chartDom.length; i++) {
    if (chartDom[i].clientWidth === 0 || chartDom[i].clientHeight === 0) {
      setTimeout(initDeliveryErrorsChart, 100);
      return;
    }

    const deliveryErrorsData = JSON.parse(chartDom[i].dataset.deliveryErrors);
    const errorInstructionDiv = document.getElementById('delivery-errors-instruction');
    const errorMessageDiv = document.getElementById('delivery-errors-message');
    const errorTable = document.getElementById('delivery-errors-table');
    errorTable.style.display = "none";

    const dates = Object.keys(deliveryErrorsData);
    const errorPercentages = dates.map(date => {
      return deliveryErrorsData[date].reduce((sum, error) => sum + error.percentage, 0);
    });

    const myChart = echarts.init(chartDom[i]);

    const option = {
      title: { text: 'Delivery Errors Over Time', left: 'center' },
      tooltip: { trigger: 'axis', axisPointer: { type: 'line' } },
      grid: { left: '10%', right: '10%', top: '15%', bottom: '15%' },
      xAxis: { type: 'category', data: dates, splitLine: { show: true } },
      yAxis: { type: 'value', axisLabel: { formatter: '{value}%' }, splitLine: { show: true } },
      series: [{
        name: 'Error Percentage',
        type: 'line',
        data: errorPercentages,
        itemStyle: { color: '#1e88e5' }
      }]
    };

    myChart.setOption(option);

    myChart.on('click', function(params) {
      const clickedDate = params.name;
      const formattedDate = new Date(clickedDate).toLocaleDateString('en-US', { month: 'short', day: '2-digit' });
      const errorsList = deliveryErrorsData[clickedDate] || [];

      errorInstructionDiv.style.display = "none";
      errorMessageDiv.style.display = "block";
      errorMessageDiv.innerText = `Delivery errors for ${formattedDate}`;

      if (errorsList.length > 0) {
        let tableHtml = `<tr><th class="border border-gray-300 px-4 py-2 triple-column">Error Type</th><th class="border border-gray-300 px-4 py-2 triple-column">Reason</th><th class="border border-gray-300 px-4 py-2 triple-column">Percentage</th></tr>`;
        errorsList.forEach(error => {
          tableHtml += `<tr><td class="border border-gray-300 px-4 py-2 triple-column">${error.error_type}</td><td class="border border-gray-300 px-4 py-2 triple-column">${error.reason}</td><td class="border border-gray-300 px-4 py-2 triple-column">${error.percentage.toFixed(1)}%</td></tr>`;
        });

        errorTable.innerHTML = tableHtml;
        errorTable.style.display = "table";
      } else {
        errorTable.style.display = "none";
      }
    });
  }
};

$(document).on('turbolinks:load', () => {
  setTimeout(initSpamRateChart, 100);
  setTimeout(initIpReputationChart, 100);
  setTimeout(initDomainReputationChart, 100);
  setTimeout(initFeedbackLoopChart, 100);
  setTimeout(initAuthenticationChart, 100);
  setTimeout(initEncryptionChart, 100);
  setTimeout(initDeliveryErrorsChart, 100);
});
