import * as echarts from 'echarts';

var myChartList = []; 
let initPlacementChart = () => {
  const chartDom = document.getElementsByClassName('placement');
  if (chartDom != null){
    for (var i=0; i<chartDom.length; i++) {

      const emailPlacement = JSON.parse(chartDom[i].dataset.emailPlacement);
      const myChart = echarts.init(chartDom[i]);

      const option = {
        xAxis: {
          type: 'category',
          data: ['Inbox', 'Spam', 'Undelivered']
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: [
            { value: emailPlacement[0], itemStyle: { color: '#82b54b' }},
            { value: emailPlacement[1], itemStyle: { color: '#e04f1a' }},
            { value: emailPlacement[2], itemStyle: { color: '#6c757d' }}
          ],
          type: 'bar'
        }]
      };

      myChart.setOption(option);
      myChartList.push(myChart);
    }
  }
}

$(document).on('turbolinks:load', () => initPlacementChart())
.on('turbolinks:before-cache', function () {
    if (window.location.href.match('/spam_tests/')) {
        const chartDom = document.getElementsByClassName('placement');
        myChartList.forEach((item) => item.dispose());
    }
});

