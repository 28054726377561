/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Rails functionality
const $ = require('jquery');
window.$ = $;
window.jQuery = jQuery;

import 'bootstrap';
import 'vendor/_es6/main/app';

window.Rails = require("@rails/ujs")
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")
require("jquery")
require("@nathanvda/cocoon")


// // Stimulus controllers
import "controllers"
// Jumpstart Pro & other Functionality
const components = require.context("src", true)
components.keys().forEach(components)

import LocalTime from "local-time"
LocalTime.start()

// ADD YOUR JAVACSRIPT HERE

// Start Rails UJS
Rails.start()

import "app/notification_policies";
import "app/show_card_form";
import "app/compare_test_results"
import "app/show_user_monitors";
import '../misc/placement_chart';
import '../misc/hide_modal';
import '../misc/ip_reputation_chart';
import '../misc/postmaster_data_charts';
import "app/upload_eml_file";
import "app/accounts";
import "app/auto_refresh_results";
import "app/postmaster_accounts";
